import { motion } from 'framer-motion';
import Image from 'next/image';
import Link from 'next/link';
import Script from 'next/script';
import { useTranslation } from 'next-i18next';
import React from 'react';

import SecondaryLogo from '../logos/SecondaryLogo';

export interface FooterMenu {
  title: string;
  url: string;
  items: {
    title: string;
    url: string;
  }[];
}

const menus: FooterMenu[] = [
  {
    title: 'services',
    url: '/services/',
    items: [
      {
        title: 'consultancy',
        url: '/solutions-consultancy/',
      },
      {
        title: 'development',
        url: '/software-development/',
      },
      {
        title: 'webdesign',
        url: '/web-design/',
      },
      {
        title: 'softwareservices',
        url: '/software-it-services/',
      },
    ],
  },
  // {
  //   title: 'solutions',
  //   url: '/#solutions',
  //   items: [
  //     {
  //       title: 'sbus',
  //       url: '/#sbus',
  //     },
  //     {
  //       title: 'smotel',
  //       url: '/#smotel',
  //     },
  //   ],
  // },
  {
    title: 'home',
    url: '/#home',
    items: [
      {
        title: 'aboutus',
        url: '/aboutus',
      },
      {
        title: 'expertise',
        url: '/expertise',
      },
      {
        title: 'services',
        url: '/services',
      },
      {
        title: 'contact',
        url: '/contact',
      },
    ],
  },
];

const FooterGroupMenu = ({ title, url, items }: FooterMenu) => {
  const { t } = useTranslation('common');
  return (
    <div className="box-border flex flex-col">
      <Link href={url} className="mb-1">
        <span className="!text-gray-300 underline">
          {t(`footer.${title}.title`)}
        </span>
      </Link>
      <ul>
        {items?.map((item) => (
          <li key={item.title}>
            <div className="mt-2">
              <Link href={item.url}>
                <span className="mt-2 font-medium !text-gray-300">
                  {t(`footer.${title}.${item.title}`)}
                </span>
              </Link>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

const ContactInfo = ({ title, value }: { title: string; value: string }) => {
  return <span className="text-gray-300">{`${title}: ${value}`}</span>;
};

export default function Footer() {
  const { t } = useTranslation('common');

  return (
    <footer className="bg-gradient">
      <div className="pt-20">
        <motion.div
          className="relative mx-auto px-5 sm:container xl:max-w-screen-xl"
          variants={{
            hidden: { y: 40, opacity: 0 },
            visible: {
              y: 0,
              opacity: 1,
              transition: {
                delay: 0.3,
                duration: 0.7,
              },
            },
          }}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
        >
          <div className="grid grid-cols-1 gap-12 lg:grid-cols-2 xl:gap-24">
            <div className="flex flex-col">
              <div className="mb-4 flex w-max items-center justify-center rounded-2xl">
                <SecondaryLogo />
              </div>
              <div className="flex flex-col gap-2">
                <span className="text-gray-300">{t('footer.company')}</span>
                <ContactInfo
                  title={t('contact.tax.title')}
                  value={t('contact.tax.value')}
                />
                <ContactInfo
                  title={t('contact.address.title')}
                  value={t('contact.address.value')}
                />
                <ContactInfo
                  title={t('contact.hotline.title')}
                  value={t('contact.hotline.value')}
                />
                <ContactInfo
                  title={t('contact.email.title')}
                  value={t('contact.email.value')}
                />
              </div>
            </div>
            <div>
              <div className="mb-10 grid grid-cols-2 gap-12 lg:gap-24">
                {menus.map((menu) => (
                  <FooterGroupMenu key={menu.title} {...menu} />
                ))}
              </div>
            </div>
          </div>
          <div className="relative mx-auto mt-5 flex flex-col items-center border-t border-gray-400 py-10">
            <a
              href="//www.dmca.com/Protection/Status.aspx?ID=7a1fecf9-e82e-4542-b0e3-5655164de31c"
              title="DMCA.com Protection Status"
              className="relative h-5 w-24"
              target="_blank"
            >
              <Image
                fill={true}
                sizes="100px 20px"
                src="https://images.dmca.com/Badges/dmca-badge-w100-5x1-07.png?ID=7a1fecf9-e82e-4542-b0e3-5655164de31c"
                alt="DMCA.com Protection Status"
              />
            </a>
            <Script
              src="https://images.dmca.com/Badges/DMCABadgeHelper.min.js"
              async={true}
            />
            <p className=" text-center text-xs text-gray-300">
              ©2023 Rizerssoft™. All Rights Reserved.
            </p>
          </div>
        </motion.div>
      </div>
    </footer>
  );
}
