import Link from 'next/link';
import { useRouter } from 'next/router';

const LanguageSwitcher = ({ locale, children, ...props }: any) => {
  const router = useRouter();
  const href = props.href || router.asPath;
  return (
    <Link href={href} locale={locale} replace>
      {children}
    </Link>
  );
};

export default LanguageSwitcher;
