export interface HeaderMenu {
  title: string;
  url?: string;
  subMenus?: {
    title: string;
    url?: string;
  }[];
}

export const menu: HeaderMenu[] = [
  {
    title: 'aboutus',
    url: '/aboutus',
  },
  {
    title: 'services',
    url: '/services',
    subMenus: [
      {
        title: 'overview',
        url: '/services',
      },
      {
        title: 'consultancy',
        url: '/solutions-consultancy',
      },
      {
        title: 'development',
        url: '/software-development',
      },
      {
        title: 'webdesign',
        url: '/web-design',
      },
      {
        title: 'softwareservices',
        url: '/software-it-services',
      },
    ],
  },
  {
    title: 'expertise',
    url: '/expertise',
  },
  {
    title: 'contact',
    url: '/contact',
  },
];

export const getLanguageFlag = (locale?: string) => {
  switch (locale) {
    case 'vi':
      return '🇻🇳';
    default:
      return '🇬🇧';
  }
};
