import { motion } from 'framer-motion';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React, { useEffect, useRef, useState } from 'react';

import LanguageSwitcher from '../common/LanguageSwitcher';
import PrimaryLogo from '../logos/PrimaryLogo';
import SecondaryLogo from '../logos/SecondaryLogo';
import { getLanguageFlag, menu } from './menus';

const locales = ['en', 'vi'];

export default function Header() {
  const { t } = useTranslation('common');
  const { locale } = useRouter();
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState(false);
  const containerRef = useRef<HTMLHeadElement>(null);

  useEffect(() => {
    if (window.scrollY > 0) {
      setScroll(true);
    } else {
      setScroll(false);
    }

    const clickHandler = ({ target }: MouseEvent) => {
      if (
        containerRef.current &&
        containerRef.current.contains(target as Node)
      ) {
        return;
      }
      setOpen(false);
    };
    document.addEventListener('mousedown', clickHandler);

    const keyHandler = ({ keyCode }: KeyboardEvent) => {
      if (keyCode !== 27) return;
      setOpen(false);
    };
    document.addEventListener('keydown', keyHandler);

    const scrollHandler = () => {
      if (window.scrollY > 0) {
        setScroll(true);
      } else {
        setScroll(false);
      }
    };
    document.addEventListener('scroll', scrollHandler);

    return () => {
      document.removeEventListener('mousedown', clickHandler);
      document.removeEventListener('keydown', keyHandler);
      document.removeEventListener('scroll', scrollHandler);
    };
  }, []);

  return (
    <>
      <div
        className={`fixed top-0 h-screen w-full ${
          open ? 'z-40 bg-black/40' : '-z-50'
        }`}
      />
      <header
        ref={containerRef}
        className={`fixed inset-x-0 top-0 z-50 transition-header duration-300 ease-linear will-change-header ${
          scroll ? 'bg-white py-0 shadow-md' : 'bg-transparent pt-10'
        } ${open && '!bg-white !pt-0 !shadow-md'}`}
      >
        <div className="relative mx-auto px-5 py-3 sm:container xl:max-w-screen-xl">
          <nav className="flex flex-row justify-between">
            <motion.div
              className="flex-1"
              variants={{
                hidden: { opacity: 0 },
                visible: {
                  opacity: 1,
                  transition: {
                    delay: 0.3,
                    duration: 0.7,
                  },
                },
              }}
              initial="hidden"
              animate="visible"
            >
              {open || scroll ? <PrimaryLogo /> : <SecondaryLogo />}
            </motion.div>
            <div className="my-auto hidden grow justify-center lg:flex">
              <motion.ul
                className="flex flex-row"
                variants={{
                  hidden: { opacity: 1 },
                  visible: {
                    opacity: 1,
                    transition: {
                      delayChildren: 0.2,
                      staggerChildren: 0.1,
                    },
                  },
                }}
                initial="hidden"
                animate="visible"
              >
                {menu.map((item, i) => (
                  <motion.li
                    key={i}
                    className="m-0 px-5 py-2"
                    variants={{
                      hidden: { y: -20, opacity: 0 },
                      visible: {
                        y: 0,
                        opacity: 1,
                        transition: {
                          duration: 0.3,
                        },
                      },
                    }}
                  >
                    <div className="group">
                      <Link
                        href={item.url || '/#home'}
                        className={`text-lg font-bold transition-opacity duration-300 ease-out will-change-opacity hover:opacity-50 xl:text-xl ${
                          scroll || open ? 'text-gradient' : 'text-white'
                        }`}
                      >
                        {t(`header.${item.title}`)}
                      </Link>
                      {item.subMenus && (
                        <div className="relative hidden flex-col group-hover:block">
                          <ul className="absolute rounded bg-white px-4 py-2 shadow-md">
                            {item.subMenus.map((subMenu, subMenuIndex) => (
                              <li key={subMenuIndex}>
                                <Link href={subMenu.url || '/#home'}>
                                  <div className="py-1">
                                    <span className="whitespace-nowrap py-5 text-gray-700 transition-opacity duration-300 ease-out will-change-opacity hover:opacity-50 xl:text-lg">
                                      {t(`header.${subMenu.title}`)}
                                    </span>
                                  </div>
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  </motion.li>
                ))}
              </motion.ul>
            </div>
            <motion.div
              className="my-auto flex flex-1 flex-row justify-end space-x-4"
              variants={{
                hidden: { opacity: 0 },
                visible: {
                  opacity: 1,
                  transition: {
                    delay: 0.3,
                    duration: 0.7,
                  },
                },
              }}
              initial="hidden"
              animate="visible"
            >
              <div className="group my-auto hidden flex-col items-end justify-center hover:cursor-pointer sm:flex">
                <div className="relative px-4 py-2">
                  <span
                    className={`inline-block transition-opacity duration-300 ease-out will-change-opacity hover:opacity-50 xl:text-base ${
                      scroll || open ? 'text-gray-700' : 'text-white'
                    }`}
                  >
                    <span className="mr-2">{getLanguageFlag(locale)}</span>
                    {t(`languages.${locale}`)}
                  </span>
                </div>
                <div className="relative hidden w-max flex-col items-end group-hover:flex">
                  <ul className="absolute rounded bg-white px-4 py-2 text-right shadow-md">
                    {locales
                      ?.filter((lang) => lang !== locale)
                      .map((lang) => (
                        <li key={lang} className="m-0">
                          <LanguageSwitcher locale={lang}>
                            <span className="inline-block w-max text-justify text-gray-700 transition-opacity duration-300 ease-out will-change-opacity hover:opacity-50 xl:text-base">
                              <span className="mr-2">
                                {getLanguageFlag(lang)}
                              </span>
                              {t(`languages.${lang}`)}
                            </span>
                          </LanguageSwitcher>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
              <div
                className={`group my-auto p-2 text-lg duration-300 ease-in hover:cursor-pointer lg:hidden ${
                  scroll || open ? 'text-gradient' : 'text-white'
                }`}
                onClick={() => setOpen((old) => !old)}
              >
                <div className="pointer-events-none relative flex h-4 w-4 flex-col justify-between">
                  <span
                    className={`mb-1 block h-0.5 w-4 origin-top-left transition-hamburger duration-300 ease-out will-change-hamburger ${
                      open && 'rotate-45 scale-x-[1.27]'
                    } ${scroll || open ? 'bg-gradient' : 'bg-white'}`}
                  />
                  <span
                    className={`mb-1 block h-0.5 w-4 transition-hamburger duration-300 ease-out will-change-hamburger ${
                      open && 'opacity-0'
                    } ${scroll || open ? 'bg-gradient' : 'bg-white'}`}
                  />
                  <span
                    className={`block h-0.5 w-4 origin-bottom-left transition-hamburger duration-300 ease-out will-change-hamburger ${
                      open && '-rotate-45 scale-x-[1.27]'
                    } ${scroll || open ? 'bg-gradient' : 'bg-white'}`}
                  />
                </div>
              </div>{' '}
            </motion.div>
          </nav>
        </div>
        <div
          className={`overflow-hidden transition-all duration-100 ease-in will-change-opacity-transform ${
            open ? 'max-h-screen shadow' : 'max-h-0'
          }`}
          onClick={() => setOpen(false)}
        >
          <div className="relative mx-auto px-5 py-3 sm:container xl:max-w-screen-xl">
            <nav>
              <ul className="flex flex-col">
                {menu.map((item, i) => (
                  <li key={i} className="items-center px-4 py-2">
                    <Link
                      href={item.url || '/#home'}
                      className={`text-lg font-semibold transition-opacity-transform duration-300 ease-out will-change-opacity-transform hover:opacity-50 ${
                        scroll || open ? 'text-gradient' : 'text-white'
                      }`}
                    >
                      {t(`header.${item.title}`)}
                    </Link>
                    <ul>
                      {item.subMenus?.map((subMenu, subMenuIndex) => (
                        <li
                          key={subMenuIndex}
                          className="items-center px-4 py-2"
                        >
                          <Link
                            href={subMenu.url || '/#home'}
                            className={`text-lg font-semibold transition-opacity-transform duration-300 ease-out will-change-opacity-transform hover:opacity-50 ${
                              scroll || open ? 'text-gradient' : 'text-white'
                            }`}
                          >
                            {t(`header.${subMenu.title}`)}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </li>
                ))}
              </ul>
              <div className="my-4 border-b" />
              <ul className="flex flex-col">
                {locales.map((lang) => (
                  <li key={lang} className="px-5 py-2">
                    <LanguageSwitcher locale={lang}>
                      <span className="inline-block w-max text-justify text-gray-700 transition-opacity duration-300 ease-out will-change-opacity hover:opacity-50 xl:text-base">
                        <span className="mr-2">{getLanguageFlag(lang)}</span>
                        {t(`languages.${lang}`)}
                      </span>
                    </LanguageSwitcher>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </div>
      </header>
    </>
  );
}
